import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';
import { PermissionService } from '../permission/permission.service';
import { Policies } from '../permission/policy.constant';

@Injectable()
export class SettingPolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'setting';

    constructor(
        protected permissionService: PermissionService,
    ) {
        super(permissionService);
    }

    inviteMember() {
        return this.permissionService.hasPolicy('setting::invite-member');
    }

    addNewGroup() {
        return this.permissionService.hasPolicy('setting::add-new-group');
    }

    viewSubscription() {
        return this.permissionService.hasPolicy('setting::subscription');
    }

    invoice() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::invoice') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].invoice);
    }

    caseSubmission() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::caseSubmission') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].caseSubmission);
    }

    zoom() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::zoom') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].zoom);
    }

    orgInfo() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::orgInfo') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].orgInfo);
    }

    kyc() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::kyc') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].kyc);
    }

    xero() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::xero') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].xero);
    }

    webhook() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::webhook') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].webhook);
    }
    document() {
        // return false;
        // console.log('document policy --> ',this.permissionService.hasPolicy(this.policyCollectionKey + '::document'))
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::document') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].document);
    }

    mapping() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::mapping') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].mapping);
    }

    modifyMappingOwner(createdById: string) {
        return this.permissionService.hasPermissions([
            'content-builder::update:mapping',
            'content-builder::delete:mapping',
        ]) && this.permissionService.user.id === +createdById;
    }
}
