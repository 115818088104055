import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { FieldBinding } from './setting.model';
import { SettingEntityModel } from './setting-entity.model';
import { Default } from '@app/core/decorators/default.decorator';
import { DocumentTemplateInterface, DocumentTemplateModel } from './document-template.model';
import { UserInterface, UserModel } from './user.model';
import { DynamicField } from './dynamic-field.model';
import { GroupDataFieldModel } from './group-data-field.model';
import { SignatureField } from './signature-field.model';
import { ParticipantField } from './participant-field.model';
import { ITemplateMappingOptionItem } from '@app/modules/template-mapping/template-mapping-option.interface';
@Exclude()
export class TemplateMappingValueMatchModel extends BaseModel {
    @Expose()
    @Default({})
    dynamicField: {[x : string]:string};
    @Expose()
    @Default({})
    signatureField: {[x : string]:string};
    @Expose()
    @Default({})
    participantField: {[x : string]:string};
}
@Exclude()
export class TemplateMappingValueModel extends BaseModel {
   
    @Expose()
    matchType: 'CaseEntity'
    @Expose()
    @Default({})
    match: {[x : string]:string};
}
export interface ITemplateMapping{
    id?:number
    keyName: string;
    displayName: string;
    description: string;
    value: TemplateMappingValueModel;

    templateId: number;
    template: DocumentTemplateInterface;
    businessUnitId?: number;
    createdAt?: string;
    createdByUser?: UserInterface;
    error?: IMappingError;
    templateName?: string;
}
@Exclude()
export class TemplateMappingModel extends BaseModel implements ITemplateMapping {
    @Expose()
    id:number
    @Expose()
    keyName: string;

    @Expose()
    displayName: string;

    @Expose()
    description: string;
    @Expose()
    templateId: number;

    @Expose()
    @Type(()=>DocumentTemplateModel)
    template:DocumentTemplateModel

    @Expose()
    value: TemplateMappingValueModel;

    @Expose()
    businessUnitId?: number;

    @Expose()
    createdAt?: string;

    @Expose()
    @Type(()=> UserModel)
    createdByUser?: UserModel;

    @Expose()
    @Transform((value, object: ITemplateMapping) => {
        const errors: Array<IMappingError> = [];

        if (!object.template) {
            errors.push({
                statusCode: ERROR_MAPPING_CODE.TMR0001,
                message: 'error_msg_by_code.' + ERROR_MAPPING_CODE.TMR0001,
            });
        }

        return errors.length ? errors.concat(errors) : undefined;
    })
    errors?: Array<IMappingError>;

    @Expose()
    @Transform((value, object: ITemplateMapping) => {
        return object.template?.name ?? '';
    })
    templateName?: string;
}

export interface IMappingError {
    statusCode: string;
    message: string;
}

export interface MappingFormInput {
    dynamicFields: DynamicField[];
    groupFieldMap: Map<string, GroupDataFieldModel[]>;
    signatureFields: SignatureField[];
    participantFields: ParticipantField[];
}

export type OptionChangeFn = (options: ITemplateMappingOptionItem[]) => void;

export function flattenArray(items: ITemplateMappingOptionItem[]): ITemplateMappingOptionItem[] {
    return items.reduce((acc, item) => {
      // Add the current item
      const flattened = [...acc, { key: item.key, name: item.name, path: item.path }];
      
      // Recursively flatten children if they exist
      if (item.children && item.children.length > 0) {
        return [...flattened, ...flattenArray(item.children)];
      }
  
      return flattened;
    }, [] as ITemplateMappingOptionItem[]);
}

export enum ERROR_MAPPING_CODE {
    TMR0001 = 'TMR0001',
}
