export class PolicyConstant {
    static MODULES = [
        {
            key: 'dashboard',
            name: 'common.dashboard',
            isOwner: false,
            actions: [
                {
                    key: 'view',
                    name: 'View personal dashboard',
                    permissions: [],
                },
                {
                    key: 'view-company',
                    name: 'View company dashboard',
                    permissions: ['analytic::read'],
                },
            ],
        },
        {
            key: 'case-submission',
            name: 'common.case_submission',
            isOwner: false,
            actions: [
                {
                    key: 'own',
                    name: 'My case',
                    permissions: [
                        'case::read',
                        'case::create',
                        'case::update',
                        'commission::read:salesperson',
                    ],
                },
                {
                    key: 'view',
                    name: 'Case submission',
                    permissions: [
                        'case::read:submission',
                        'case::approve:submission',
                        'case::process:submission',
                        'commission::read:salesperson',
                    ],
                },
            ],
        },
        {
            key: 'personal-case',
            name: 'Personal case',
            isOwner: false,
            actions: [
                {
                    key: 'own',
                    name: 'Case',
                    permissions: [
                        'case::read',
                        'case::create',
                        'case::update',
                        'case::process:submission',
                    ],
                },
            ],
        },
        {
            key: 'invoice',
            name: 'common.invoice',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['invoice::read', 'field::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: [
                        'invoice::create',
                        'field::read',
                        'contract::create',
                        'contract::start',
                        'contract::stop',
                        'field::create',
                        'bu::read:user',
                        'commission::read:salesperson',
                    ],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: [
                        'invoice::update',
                        'field::read',
                        'contract::update',
                        'contract::start',
                        'contract::stop',
                        'field::update',
                        'bu::read:user',
                        'commission::read:salesperson',
                    ],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['invoice::delete'],
                },
            ],
        },
        {
            key: 'receipt',
            name: 'common.receipt',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['receipt::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['receipt::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['receipt::update'],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['receipt::delete'],
                },
            ],
        },
        {
            key: 'credit-note',
            name: 'common.credit_note',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['credit-note::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['credit-note::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['credit-note::update'],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['credit-note::delete'],
                },
            ],
        }, 
        {
            key: 'supplier-invoice',
            name: 'common.bills_to_pay',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['supplier-invoice::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['supplier-invoice::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['supplier-invoice::update'],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['supplier-invoice::delete'],
                },
            ],
        },
        {
            key: 'payout',
            name: 'common.payment_voucher',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['payout::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['payout::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['payout::update'],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['payout::delete'],
                },
            ],
        },
        {
            key: 'document',
            name: 'common.document',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['contract::read', 'field::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: [
                        'contract::create',
                        'bu::read:user',
                        'contract::start',
                        'contract::stop',
                        'field::create',
                        'field::read',
                    ],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: [
                        'contract::update',
                        'bu::read:user',
                        'contract::start',
                        'contract::stop',
                        'field::update',
                        'field::read',
                    ],
                },
            ],
        },
        {
            key: 'template',
            name: 'common.template',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: [
                        'content-builder::read:template',
                        'content-builder::create:template',
                        'content-builder::delete:template',
                    ],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: [
                        'content-builder::create:template',
                        'bu::read:user',
                        'content-builder::read:template',
                    ],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: [
                        'content-builder::update:template',
                        'bu::read:user',
                    ],
                },
            ],
        },
        {
            key: 'system-template',
            name: 'common.system_template',
            isOwner: false,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['content-builder::read:template'],
                },
            ],
        },
        {
            key: 'item',
            name: 'common.property',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['item::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['item::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['item::update'],
                },
            ],
        },
        {
            key: 'client',
            name: 'common.client',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['tenant::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['tenant::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['tenant::update'],
                },
            ],
        },
        {
            key: 'co-broke-agent',
            name: 'Ext Co-broke Salesperson',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['case::read:agent'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['case::create:agent'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['case::update:agent'],
                },
            ],
        },
        {
            key: 'salesperson',
            name: 'Internal Salesperson',
            isOwner: false,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['commission::read:salesperson'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['commission::create:salesperson'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['commission::update:salesperson'],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['commission::delete:salesperson'],
                },
            ],
        },
        {
            key: 'vendor',
            name: 'common.vendor',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View All',
                    permissions: ['vendor::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['vendor::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['vendor::update'],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['vendor::delete'],
                },
            ],
        },
        {
            key: 'commission-distribution',
            name: 'Commission Distribution',
            isOwner: false,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['commission::read:distribution'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: [
                        'commission::confirm:distribution',
                        'commission::reject:distribution',
                    ],
                },
            ],
        },
        {
            key: 'commission-split-matrix',
            name: 'Commission Split Matrix',
            isOwner: false,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['commission::read:split-matrix'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['commission::create:split-matrix'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['commission::update:split-matrix'],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['commission::delete:split-matrix'],
                },
            ],
        },
        {
            key: 'promotion-matrix',
            name: 'Promotion Matrix',
            isOwner: false,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['commission::read:promotion-matrix'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: [
                        'commission::create:promotion-matrix',
                        'commission::read:split-matrix',
                    ],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: [
                        'commission::update:promotion-matrix',
                        'commission::read:split-matrix',
                    ],
                },
            ],
        },
        {
            key: 'kyc',
            name: 'common.kyc_aml',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['kyc::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['kyc::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['kyc::update'],
                },
            ],
        },
        {
            key: 'ecdd-hutton',
            name: 'common.ecdd',
            isOwner: true,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: ['ecdd::read'],
                },
                {
                    key: 'create',
                    name: 'Create all',
                    permissions: ['ecdd::create'],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: ['ecdd::update'],
                },
                {
                    key: 'delete',
                    name: 'Delete all',
                    permissions: ['ecdd::delete'],
                },
            ],
        },
        {
            key: 'setting',
            name: 'common.settings',
            isOwner: false,
            actions: [
                {
                    key: 'view',
                    name: 'View all',
                    permissions: [
                        'bu::read:user',
                        'group::read',
                        'bu::read',
                        'org::read',
                    ],
                },
                {
                    key: 'update',
                    name: 'Update all',
                    permissions: [
                        'bu::read:user',
                        'bu::read',
                        'group::read',
                        'group::update',
                        'group::assign:user',
                        'group::revoke:user',
                        'bu::update',
                        'bu::remove:user',
                        'org::update',
                        'org::read',
                    ],
                },
                {
                    key: 'activity-log',
                    name: 'View All Activity Log',
                    permissions: ['analytic::read:activity'],
                },
                {
                    key: 'access-control',
                    name: 'Access control',
                    permissions: [
                        'group::assign:permission',
                        'group::revoke:permission',
                        'group::read',
                        'group::update',
                    ],
                },
                {
                    key: 'add-new-group',
                    name: 'Add new group',
                    permissions: ['group::create'],
                },
                {
                    key: 'invite-member',
                    name: 'Invite member',
                    permissions: ['bu::add:user', 'org::add:user'],
                },
                {
                    key: 'orgInfo',
                    name: 'Organization & Business Unit',
                    permissions: [
                        'bu::read:user',
                        'bu::read',
                        'group::read',
                        'bu::update',
                        'org::update',
                        'org::read',
                    ],
                },
                {
                    key: 'subscription',
                    name: 'Manage Subscription & Billing',
                    permissions: ['subscription::read', 'subscription::update'],
                },
                {
                    key: 'invoice',
                    name: 'Manage Accounting Settings',
                    permissions: ['invoice::update', 'bu::update', 'bu::read'],
                },
                {
                    key: 'caseSubmission',
                    name: 'Manage Case Submission Requirement',
                    permissions: [
                        'case::update',
                        'case::read',
                        'bu::read:user',
                        'bu::read',
                    ],
                },
                {
                    key: 'zoom',
                    name: 'Manage Zoom Account',
                    permissions: ['org::update', 'org::read'],
                },
                {
                    key: 'kyc',
                    name: 'Manage KYC Account',
                    permissions: ['org::update', 'org::read'],
                },
                {
                    key: 'xero',
                    name: 'Manage Invoice Xero Account',
                    permissions: ['org::update', 'org::read'],
                },
                {
                    key: 'webhook',
                    name: 'Manage webhook',
                    permissions: ['org::update', 'org::read'],
                },
                {
                    key: 'document',
                    name: 'Document setting',
                    permissions: ['org::update', 'org::read'],
                },
                {
                    key: 'mapping',
                    name: 'Template Mapper',
                    permissions: [
                        'content-builder::read:mapping',
                        'content-builder::create:mapping',
                        'content-builder::update:mapping',
                        'content-builder::delete:mapping',
                    ],
                },
            ],
        },
    ];
}

const ConvertPolicies = {};
const AllPolicies = [];

PolicyConstant.MODULES.forEach((mod) => {
    ConvertPolicies[mod.key] = {};
    mod.actions.forEach((action) => {
        ConvertPolicies[mod.key][action.key] = action.permissions;

        if (action.key === 'own') {
            AllPolicies.push(mod.key + '::viewOwner');
            AllPolicies.push(mod.key + '::createOwner');
            AllPolicies.push(mod.key + '::updateOwner');
            AllPolicies.push(mod.key + '::deleteOwner');
        } else {
            AllPolicies.push(mod.key + '::' + action.key);
        }
    });
});

export const Policies = ConvertPolicies;
export const PolicyValues = AllPolicies;
